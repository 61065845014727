import React from 'react';
import { useContext } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { firestore, auth, serverTimestamp } from '@lib/firebase';
import { UserContext } from '@lib/context';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import styles from '@styles/Navbar.module.css';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
//   avatar: {
//     display: 'flex',
//     '& > *': {
//       margin: theme.spacing(1),
//     },
//   },
//   toolbar: {
//     justifyContent: 'space-between',
//   },
// }));

export default function Navbar() {
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [addAnchorEl, setAddAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { user, username } = useContext(UserContext);
  const router = useRouter();

  const signOut = () => {
    auth.signOut();
    router.reload();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddClick = (event) => {
    setAddAnchorEl(event.currentTarget);
  };

  const handleAddClose = () => {
    setAddAnchorEl(null);
  };

  const createDocument = async () => {
    const uid = auth.currentUser.uid;
    const ref = await firestore.collection('users').doc(uid).collection('posts').add({
      title: '',
      uid,
      postId: '',
      username,
      published: false,
      content: '',
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      heartCount: 0,
    });
    const slug = ref.id;

    await ref.update({
      slug,
      postId: ref.id,
    });

    return slug;
  };

  const createPost = async () => {
    const slug = await createDocument();

    router.push(`/my/${slug}`);
  };

  const createLink = async () => {
    const slug = await createDocument();

    router.push(`/my/create-link/${slug}`);
  };

  return (
    <nav className={styles.navbar}>
      <AppBar position="static" color="inherit">
        <Toolbar className={styles.toolbar}>
          <Link href="/">
            <img src={'/shukream-logo.png'} height="40px" />
          </Link>

          {/* user is not signed OR has not created username */}
          {!username && (
            <>
              <ul>
                {/* <li>
                  <Link href="/events">
                    <Button color="primary" variant="contained">
                      <div className="text-button">インターンシップ＆イベント情報 </div>
                    </Button>
                  </Link>
                </li> */}
                <li>
                  <Link href="/enter">
                    <Button color="primary" variant="outlined">
                      ログイン
                    </Button>
                  </Link>
                </li>
              </ul>
            </>
          )}

          {/* user is signed-in and has username */}
          {username && (
            <>
              {/* Create Post  */}
              <ul>
                <li>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={handleAddClick}
                  >
                    <div className="text-button">
                      {/* <EditIcon /> */}
                      投稿
                    </div>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={addAnchorEl}
                    keepMounted
                    open={Boolean(addAnchorEl)}
                    onClose={handleAddClose}
                  >
                    <MenuItem onClick={handleAddClose}>
                      <a onClick={() => createPost()}>記事</a>
                    </MenuItem>
                    <MenuItem onClick={handleAddClose}>
                      <a onClick={() => createLink()}>リンク</a>
                    </MenuItem>
                  </Menu>
                </li>
                {/* Avatar Menu */}
                <li>
                  <Avatar onClick={handleMenu} src={user?.photoURL} />
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>
                      <Link href={`/${username}`}>マイページ</Link>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <Link href={`/my`}>自分の記事</Link>
                    </MenuItem>

                    <MenuItem onClick={handleClose}>
                      <Link href={`/${username}/hearted`}>スキした記事</Link>
                    </MenuItem>

                    <MenuItem color="inherit" onClick={signOut}>
                      ログアウト
                    </MenuItem>
                  </Menu>
                </li>
              </ul>
            </>
          )}
        </Toolbar>
      </AppBar>
    </nav>
  );
}
