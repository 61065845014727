import { Toaster } from 'react-hot-toast';
import { useRouter } from 'next/router';
import Navbar from './Navbar';
import Footer from './Footer';
import LandingImage from './LandingImage';
import styles from '@styles/Layout.module.css';

export default function Layout({ children }) {
  const router = useRouter();

  return (
    <div>
      <Navbar />
      {router.pathname === '/' && <LandingImage />}
      <div className={styles.container}>{children}</div>
      <Footer />
      <Toaster />
    </div>
  );
}
