import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
  shadows: ['none'],
  palette: {
    primary: {
      main: '#FFC107',
    },
    secondary: {
      main: '#FFA000',
    },
    error: {
      main: '#26C6DA',
    },
    warning: {
      main: '#dc004e',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;
