import Link from 'next/link';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  footer: {
    // borderTop: '1px solid #eaeaea',
    margin: '50px auto',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px auto',
    gridGap: '15px',
  },
});

export default function Footer() {
  const classes = useStyles();

  return (
    <footer>
      <div className={classes.footer}>
        <div className={classes.item}>
          &copy; {new Date().getFullYear()}
          <Link href="https://nxt.shukream.com">NXT</Link>
          <Link href="/terms">利用規約</Link>
          <Link href="/privacy">プライバシー</Link>
        </div>
      </div>
    </footer>
  );
}
