import { useContext } from 'react';
import { UserContext } from '@lib/context';
import { Typography, makeStyles, Card, CardContent, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  landing: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5px auto',
    padding: '8px 0',
    background: 'rgba(244,244,244,0.6)',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '5px 0px auto',
      background: 'rgba(244,244,244,0.6)',
    },
  },
  landingInner: {
    display: 'flex',
    maxWidth: '760px',
    margin: '10px auto',
    padding: '0 10px 0 16px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  landingText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: '0 32px',
    },
  },
  landingTitle: {
    [theme.breakpoints.down('xs')]: {
      marginTop: '24px',
      fontSize: '24px',
    },
  },
  landingSubtitle: {
    marginTop: '16px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '16px',
    },
  },
  imageWrapper: {
    minWidth: '300px',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
}));

export default function LandingImage() {
  const { username } = useContext(UserContext);
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (username) {
    return null;
  }

  return (
    <div className={classes.landing}>
      <div className={classes.landingInner}>
        <div className={classes.landingText}>
          <Typography
            gutterBottom
            component="h1"
            variant="h4"
            fontWeight="fontWeightBold"
            className={classes.landingTitle}
          >
            あなたの就活Dream
          </Typography>
          <Typography variant="subtitle1" className={classes.landingSubtitle}>
            Shukreamは就職活動に必要な情報共有コミュニティです。
          </Typography>
        </div>
        <div className={classes.imageWrapper}>
          <img src={'/landing-illust.png'} width="300" height="225" />
        </div>
      </div>
    </div>
  );
}
