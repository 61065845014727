import React from 'react';
import { useRouter } from 'next/router';
import * as gtag from '@lib/gtag';
import { UserContext } from '@lib/context';
import { useUserData } from '@lib/hooks';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from '@components/theme';
import Layout from '@components/Layout';
import '@styles/globals.css';

function MyApp({ Component, pageProps, err }) {
  const userData = useUserData();
  React.useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const router = useRouter();
  React.useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <UserContext.Provider value={userData}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Layout>
          <Component {...pageProps} err={err} />
        </Layout>
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default MyApp;
